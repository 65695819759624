<template>

  <!-- #region Delete confirm -->
  <CModal
    color="warning"
    :closeOnBackdrop="false"
    :centered="true"
    :show.sync="resetChangeModal"
  >
    {{ $t("roomInventoryEditPage.resetChangesConfirmModalCaption") }}
    <template #header> {{ $t("roomInventoryEditPage.resetChanges") }} </template>
    <template #footer>
      <CButton
        @click="$emit('close', false)"
        color="secondary"
      >
        <i class="icon cil-x"></i> {{$t('common.cancel')}}
      </CButton>
      <CButton
        @click="callback"
        color="danger"
      >
        <i class="icon cil-sync"></i> {{$t('common.run')}}
      </CButton>
    </template>
  </CModal>
  <!-- #endregion Delete confirm -->

</template>

<script>

export default {
  name: "ResetChangeModal",

  props: {

    resetChangeModal: {
      type: Boolean,
      required: true,
      default: false,
    },

    callback: {
      type: Function,
    }

  },

};
</script>

<template>
  <CModal
    color="info"
    size="lg"
    :closeOnBackdrop="false"
    :centered="true"
    :show.sync="bulkChangeModal"
  >

    <!-- #region Body -->
    <CAlert
      color="danger"
      class="mt-3 text-center"
      v-if="canNotUpdate"
    >
      {{ alertMessage }}
    </CAlert>

    <CForm class="submit_form">

      <CRow>
        <!-- TODO: レイアウト調整 -->
        <CCol
          class="label"
          md="2"
        ><strong>設定期間</strong></CCol>
        <CCol md="2">
          <DatePicker
            v-model="startDate"
            :min-date="today()"
            :popover="{ visibility: 'click' }"
            @dayclick="onStartDateDayClicked()"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="border px-2 py-1 rounded"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </CCol>
        ～
        <CCol md="2">
          <DatePicker
            v-model="endDate"
            :max-date="dayBeforeOneYearLater()"
            :popover="{ visibility: 'click' }"
            @change="onEndDateDayClicked()"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="border px-2 py-1 rounded"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </CCol>
      </CRow>

      <CRow class="my-2">
        <CCol
          class="label"
          md="2"
        ><strong>一括設定内容</strong></CCol>
        <CCol>
          <CInputRadioGroup
            :options="modeList"
            :checked.sync="mode"
            inline
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol>
          <CDataTable
            hover
            border
            size="sm"
            :fields="tableFields"
            :items="tableItems"
          >
            <td
              slot="selected"
              slot-scope="{ item }"
              class="text-left"
            >
              <CInputCheckbox
                :checked.sync="item.isUpdate"
                size="lg"
              />
            </td>
            <td
              slot="name"
              slot-scope="{ item }"
              class="text-left"
            >
              <CInput
                class="w-6"
                size="sm"
                v-model="item.name"
                plaintext
              />
            </td>
            <td
              slot="standardInventory"
              slot-scope="{ item }"
              class="text-left"
            >
              <CInput
                class="w-6"
                size="sm"
                v-model.number="item.standardInventory"
                type="number"
                plaintext
              />
            </td>
            <td
              slot="provisionNumber"
              slot-scope="{ item }"
              class="text-left"
            >
              <CInput
                class="w-6"
                size="sm"
                v-model.number="item.provisionNumber"
                type="number"
                @update:value="onProvisionNumberInput(item)"
                :disabled="isProvisionNumber"
              />
            </td>
            <td
              slot="maxReservedNumber"
              slot-scope="{ item }"
              class="text-left"
            >
              <CInput
                class="w-6"
                size="sm"
                v-model.number="item.maxReservedNumber"
                type="number"
                plaintext
              />
            </td>
            <td
              slot="remainNumber"
              slot-scope="{ item }"
              class="text-center"
            >
              <CInput
                class="w-6"
                size="sm"
                v-model.number="item.remainNumber"
                type="number"
                @update:value="onRemainNumberInput(item)"
                :disabled="isRemainNumber"
              />
            </td>
            <td
              slot="isSales"
              slot-scope="{ item }"
              class="text-left"
            >
              <CSwitch
                class="mt-1 swc sw-2"
                color="info"
                variant="opposite"
                :labelOn='$t("roomInventoryEditPage.sales")'
                :labelOff='$t("roomInventoryEditPage.stop")'
                :checked.sync="item.isSales"
                :disabled="isIsSales"
              />
            </td>
          </CDataTable>
          <p>
            ※対象にチェックした部屋のみ設定対象となります。<br />
            ※提供数は「基準在庫数以下」および「残室数+予約数以上」となる値を設定してください。<br />
            ※残室数は「0以上」の値を設定してください。<br />
            ※予約数は設定期間内の最大予約数を表しています。
          </p>
        </CCol>
      </CRow>

    </CForm>
    <!-- #endregion Body -->

    <template #header>
      <h4 class="modal-title">{{$t('roomInventoryEditPage.bulkChangeInventory')}}</h4>
    </template>

    <template #footer>
      <CButton
        @click="closeModal()"
        color="secondary"
      ><i class="icon cil-x"></i> {{ $t("common.cancel") }}</CButton>

      <CButton
        @click="onReflectClicked()"
        color="info"
        :disabled="canReflect"
      >
        <i class="icon cil-sync"></i> {{$t('common.reflect')}}
      </CButton>

    </template>

  </CModal>
</template>

<script>
import i18n from "@/i18n";
import MODE from "@/mixins/property";

export default {
  name: "BulkChangeModal",

  mixins: [MODE],

  props: {
    bulkChangeModal: {
      type: Boolean,
      default: false,
    },

    tableItems: {
      type: Array,
    },
  },

  computed: {
    isProvisionNumber() {
      return this.mode == this.MODE.CHANGE_PROVISION_NUMBER
        ? undefined
        : "disabled";
    },

    isRemainNumber() {
      return this.mode == this.MODE.CHANGE_REMAIN_NUMBER
        ? undefined
        : "disabled";
    },

    isIsSales() {
      return this.mode == this.MODE.CHANGE_IS_SALES ? undefined : "disabled";
    },

    canReflect() {
      return this.tableItems.find(a => a.isUpdate) ? undefined : "disabled";
    }
  },

  data() {
    return {
      mode: "",
      canNotUpdate: false,
      alertMessage: "",

      startDate: this.today(),
      endDate: this.dayBeforeOneYearLater(),

      tableFields: [
        {
          key: "selected",
          label: `${i18n.t("roomInventoryEditPage.settingTarget")}`,
          _classes: "text-center bg-light",
        },
        {
          key: "name",
          label: `${i18n.t("roomInventoryEditPage.roomName")}`,
          _classes: "text-center bg-light",
        },
        {
          key: "standardInventory",
          label: `${i18n.t("roomInventoryEditPage.standardInventory")}`,
          _classes: "text-center bg-light",
        },
        {
          key: "provisionNumber",
          label: `${i18n.t("roomInventoryEditPage.provisionNumber")}`,
          _classes: "text-center bg-light",
        },
        {
          key: "maxReservedNumber",
          label: `${i18n.t("roomInventoryEditPage.reservedNumber")}`,
          _classes: "text-center bg-light",
        },
        {
          key: "remainNumber",
          label: `${i18n.t("roomInventoryEditPage.remainNumber")}`,
          _classes: "text-center bg-light",
        },
        {
          key: "isSales",
          label: `${i18n.t("roomInventoryEditPage.isSales")}`,
          _classes: "text-center bg-light",
        },
      ],

      modeList: [], // NOTE: mixinsの定数がここで読み込めない
    };
  },

  methods: {
    //#region Event

    /** 設定期間開始日変更 */
    onStartDateDayClicked() {
      this.calcMaxReservedNumber();
    },

    /** 設定期間終了日変更 */
    onEndDateDayClicked() {
      this.calcMaxReservedNumber();
    },

    /** 提供数入力 */
    onProvisionNumberInput(item) {
      // 提供数が基準在庫数を超過した場合、提供数に基準在庫数をセット
      if (item.provisionNumber > item.standardInventory) {
        item.provisionNumber = item.standardInventory;
      }

      // 提供数が予約数を下回った場合、提供数に予約数をセット
      if (item.provisionNumber - item.maxReservedNumber < 0) {
        item.provisionNumber = item.maxReservedNumber;
      }

      item.remainNumber = item.provisionNumber - item.maxReservedNumber;
    },

    /** 残室数入力 */
    onRemainNumberInput(item) {
      // 在庫数＋予約数が基準在庫数を超過した場合、残室数に基準在庫数-予約数をセット
      if (item.remainNumber + item.maxReservedNumber > item.standardInventory) {
        item.remainNumber = item.standardInventory - item.maxReservedNumber;
      }

      if (item.remainNumber < 0) {
        item.remainNumber = 0;
      }

      item.provisionNumber = item.remainNumber + item.maxReservedNumber;
    },

    /** 反映ボタン押下 */
    onReflectClicked() {
      !this.isProvisionNumber && this.bulkChangeByProvisionNumber();
      !this.isRemainNumber && this.bulkChangeByRemainNumber();
      !this.isIsSales && this.bulkChangeByIsSales();
    },

    //#endregion Event

    //#region Method

    /** 提供数指定による一括在庫設定 */
    bulkChangeByProvisionNumber() {
      // console.log(this.tableItems);
      this.$emit('bulkChangeByProvisionNumber', this.tableItems);
      this.closeModal();
    },

    /** 残室数指定による一括在庫設定 */
    bulkChangeByRemainNumber() {
      // console.log(this.tableItems);
      this.$emit('bulkChangeByRemainNumber', this.tableItems);
      this.closeModal();
    },

    /** 一括販売設定 */
    bulkChangeByIsSales() {
      // console.log(JSON.stringify(this.tableItems));
      this.$emit('bulkChangeByIsSales', this.tableItems);
      this.closeModal();
    },

    /** アラート表示 */
    showAlert(message) {
      this.alertMessage = message;
      this.canNotUpdate = true;
    },

    /** アラート表示リセット */
    resetAlert() {
      this.alertMessage = "";
      this.canNotUpdate = false;
    },

    /** モーダルを閉じる */
    closeModal() {
      this.mode = this.MODE.CHANGE_PROVISION_NUMBER;
      this.resetAlert();
      this.$emit("close", false);
    },

    // mixinsを初期値にセット
    constructor() {
      this.modeList = [
        {
          value: this.MODE.CHANGE_PROVISION_NUMBER,
          label: `${i18n.t("roomInventoryEditPage.provisionNumber")}`,
        },
        {
          value: this.MODE.CHANGE_REMAIN_NUMBER,
          label: `${i18n.t("roomInventoryEditPage.remainNumber")}`,
        },
        {
          value: this.MODE.CHANGE_IS_SALES,
          label: `${i18n.t("roomInventoryEditPage.isSales")}`,
        },
      ];
      this.mode = this.MODE.CHANGE_PROVISION_NUMBER;
    },

    /** 設定期間内の部屋ごとの最大予約数を算出 */
    calcMaxReservedNumber() {
      const startDate = this.formatDate(this.startDate);
      const endDate = this.formatDate(this.endDate);

      for (let item of this.tableItems) {
        item.maxReservedNumber = item.inventories
          .filter(a => a.date >= startDate && a.date <= endDate)
          .reduce((a, b) => a.reservedNumber > b.reservedNumber ? a : b)
          .reservedNumber;
      }
    },

    /** YYYY/MM/DD */
    formatDate(date) {
      return `${date.getFullYear()}/${('0' + (date.getMonth() + 1)).slice(-2)}/${('0' + date.getDate()).slice(-2)}`;
    },

    // TODO: あとで共通処理に
    /** 本日 */
    today() {
      return new Date();
    },

    // TODO: あとで共通処理に
    /** 1年後の前日 */
    dayBeforeOneYearLater() {
      let date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      date.setDate(date.getDate() - 1);
      return date;
    },

    //#endregion Method
  },

  beforeMount() {
    this.constructor();
    this.resetAlert();
    this.calcMaxReservedNumber();

    // console.log(this.startDate);
    // console.log(this.endDate);
  },
};
</script>
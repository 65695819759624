var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"color":"info","size":"lg","closeOnBackdrop":false,"centered":true,"show":_vm.bulkChangeModal},on:{"update:show":function($event){_vm.bulkChangeModal=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.$t('roomInventoryEditPage.bulkChangeInventory')))])]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.closeModal()}}},[_c('i',{staticClass:"icon cil-x"}),_vm._v(" "+_vm._s(_vm.$t("common.cancel")))]),_c('CButton',{attrs:{"color":"info","disabled":_vm.canReflect},on:{"click":function($event){return _vm.onReflectClicked()}}},[_c('i',{staticClass:"icon cil-sync"}),_vm._v(" "+_vm._s(_vm.$t('common.reflect'))+" ")])]},proxy:true}])},[(_vm.canNotUpdate)?_c('CAlert',{staticClass:"mt-3 text-center",attrs:{"color":"danger"}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")]):_vm._e(),_c('CForm',{staticClass:"submit_form"},[_c('CRow',[_c('CCol',{staticClass:"label",attrs:{"md":"2"}},[_c('strong',[_vm._v("設定期間")])]),_c('CCol',{attrs:{"md":"2"}},[_c('DatePicker',{attrs:{"min-date":_vm.today(),"popover":{ visibility: 'click' }},on:{"dayclick":function($event){return _vm.onStartDateDayClicked()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"border px-2 py-1 rounded",domProps:{"value":inputValue}},inputEvents))]}}]),model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_vm._v(" ～ "),_c('CCol',{attrs:{"md":"2"}},[_c('DatePicker',{attrs:{"max-date":_vm.dayBeforeOneYearLater(),"popover":{ visibility: 'click' }},on:{"change":function($event){return _vm.onEndDateDayClicked()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"border px-2 py-1 rounded",domProps:{"value":inputValue}},inputEvents))]}}]),model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('CRow',{staticClass:"my-2"},[_c('CCol',{staticClass:"label",attrs:{"md":"2"}},[_c('strong',[_vm._v("一括設定内容")])]),_c('CCol',[_c('CInputRadioGroup',{attrs:{"options":_vm.modeList,"checked":_vm.mode,"inline":""},on:{"update:checked":function($event){_vm.mode=$event}}})],1)],1),_c('CRow',[_c('CCol',[_c('CDataTable',{attrs:{"hover":"","border":"","size":"sm","fields":_vm.tableFields,"items":_vm.tableItems},scopedSlots:_vm._u([{key:"selected",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-left"},[_c('CInputCheckbox',{attrs:{"checked":item.isUpdate,"size":"lg"},on:{"update:checked":function($event){return _vm.$set(item, "isUpdate", $event)}}})],1)}},{key:"name",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-left"},[_c('CInput',{staticClass:"w-6",attrs:{"size":"sm","plaintext":""},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1)}},{key:"standardInventory",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-left"},[_c('CInput',{staticClass:"w-6",attrs:{"size":"sm","type":"number","plaintext":""},model:{value:(item.standardInventory),callback:function ($$v) {_vm.$set(item, "standardInventory", _vm._n($$v))},expression:"item.standardInventory"}})],1)}},{key:"provisionNumber",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-left"},[_c('CInput',{staticClass:"w-6",attrs:{"size":"sm","type":"number","disabled":_vm.isProvisionNumber},on:{"update:value":function($event){return _vm.onProvisionNumberInput(item)}},model:{value:(item.provisionNumber),callback:function ($$v) {_vm.$set(item, "provisionNumber", _vm._n($$v))},expression:"item.provisionNumber"}})],1)}},{key:"maxReservedNumber",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-left"},[_c('CInput',{staticClass:"w-6",attrs:{"size":"sm","type":"number","plaintext":""},model:{value:(item.maxReservedNumber),callback:function ($$v) {_vm.$set(item, "maxReservedNumber", _vm._n($$v))},expression:"item.maxReservedNumber"}})],1)}},{key:"remainNumber",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('CInput',{staticClass:"w-6",attrs:{"size":"sm","type":"number","disabled":_vm.isRemainNumber},on:{"update:value":function($event){return _vm.onRemainNumberInput(item)}},model:{value:(item.remainNumber),callback:function ($$v) {_vm.$set(item, "remainNumber", _vm._n($$v))},expression:"item.remainNumber"}})],1)}},{key:"isSales",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-left"},[_c('CSwitch',{staticClass:"mt-1 swc sw-2",attrs:{"color":"info","variant":"opposite","labelOn":_vm.$t("roomInventoryEditPage.sales"),"labelOff":_vm.$t("roomInventoryEditPage.stop"),"checked":item.isSales,"disabled":_vm.isIsSales},on:{"update:checked":function($event){return _vm.$set(item, "isSales", $event)}}})],1)}}])}),_c('p',[_vm._v(" ※対象にチェックした部屋のみ設定対象となります。"),_c('br'),_vm._v(" ※提供数は「基準在庫数以下」および「残室数+予約数以上」となる値を設定してください。"),_c('br'),_vm._v(" ※残室数は「0以上」の値を設定してください。"),_c('br'),_vm._v(" ※予約数は設定期間内の最大予約数を表しています。 ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }